@media (min-width: 769px) {

  /* width */
  .__con::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  .__con::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  .__con::-webkit-scrollbar-thumb {
    background: #dee5b6;
    /*linear-gradient(0deg, #C3996B 0%, #E3D1AC 100%);*/
    border-top: 4px solid #839f67;
    border-bottom: 4px solid #839f67;
    border-left: 1px solid #839f67;
    border-right: 1px solid #839f67;
    border-radius: 10px;
  }

  /* Handle on hover */
  .__con::-webkit-scrollbar-thumb:hover {
    background: #d2dfa0;
  }

  /* Buttons */
  /* ::-webkit-scrollbar-button:single-button {
    background-color: #bbbbbb;
    display: block;
    border-style: solid;
    height: 24px;
    width: 16px;
  } */
}

.header {
  z-index: 100;
  height: 120px !important;
  display: flex;
  position: fixed;
}

header {
  height: 120px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 100;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  backdrop-filter: saturate(120%) blur(1.5px);
  background-color: #0003;
  transition: all 0.3s;
}

header .logo {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}


header .logo img {
  max-width: 300px;
  transition: all 0.3s;
}

header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

header ul li {
  list-style: none;
  margin-left: 10px;
}

header .nav-links {
  padding: 0px;
  position: relative;
  margin-right: -30px;
}

header .nav-links li a {
  text-decoration: none;
  padding: 6px 12px;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s;
  border-bottom: 2px solid transparent;
}

header .nav-links li a:hover {
  border-bottom: 4px solid #fff3;
  /* color: #0C486C; */
}

header .nav-links li a.active {
  color: #fff;
  transition: all 1s;
}

.menu-main-links {
  scale: 1;
  transition: all 0.3s;
}

.nav-links-vertical {
  padding: 0px 10px;
  margin: 0px;
  scale: 1;
  transition: all 0.3s;
}

.nav-links-vertical .under-line {
  margin-top: 0px;
}

.nav-links-vertical li {
  list-style: none;
}

.nav-links-vertical li a {
  text-decoration: none;
  padding: 12px 12px;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s;
  width: 100%;
  display: block;
  border-bottom: 2px solid transparent;
}

.nav-links-vertical li a:hover {
  background-color: #fff3;
}

.sideMenu-vertical-section {
  position: relative;
  display: flex;
  padding: 15px;
  color: #fff;
  margin-top: 10px;
  width: 100%;
  background-color: #0e193288;
  scale: 1;
  transition: all 0.3s;
}

.sideMenu-vertical-section:hover {
  background-color: #0b1628;
  cursor: pointer;
}

.sideMenu-vertical-section:after {
  position: absolute;
  content: "";
  display: block;
  top: 30px;
  right: 20px;
  height: 0px;
  width: 0px;
  border-style: solid;
  border-color: #fffe transparent transparent transparent;
  border-width: 8px 8px 0px 8px;
}

.sideMenu-vertical-section.active:after {
  border-color: transparent transparent #fffe transparent;
  border-width: 0 8px 8px 8px;
}

.sideMenu-vertical-section.active {
  background-color: #0b1628;
}

.menu-main-links {
  height: 130px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-evenly;
}


/* .action-button {
  position: relative;
  top: 0px;
  text-decoration: none;
  padding: 6px 15px;
  color: #d677cd;
  font-size: 20px;
  padding-bottom: 10px;
  border-radius: 6px;
  color: #212b64;
  background-color: #d677cd;
  border-bottom: 4px solid #b552ab;
  transition: all 0.3s;
}

.action-button:hover {
  top: 4px;
  border-bottom: 0px solid #b552ab;
} */

/* .action-button {
  position: relative;
  top: 0px;
  text-decoration: none;
  padding: 6px 15px;
  color: #ffa70f;
  font-size: 20px;
  padding-bottom: 10px;
  border-radius: 6px;
  color: #212b64;
  background-color: #ffa70f;
  border-bottom: 4px solid #a16a0b;
  transition: all 0.3s;
}

.action-button:hover {
  top: 4px;
  border-bottom: 0px solid #a16a0b;
} */

.action-button {
  position: relative;
  top: 0px;
  text-decoration: none;
  padding: 6px 15px 10px 15px;
  font-size: 20px;
  border-radius: 4px;
  transition: all 0.3s;

  color: #231F20;
  /* background: #fdfbe4;
  border-bottom: 4px solid #75b1a1; */
  background: #dee5b6;
  border-bottom: 4px solid #839f67;
  display: block;
  opacity: 1;
}

.action-button:hover {
  top: 2px;
  color: #231F20;
  background: #dee5b6;
  border-bottom: 2px solid #839f67;
}



/* .action-button {
  position: relative;
  top: 0px;
  text-decoration: none;
  padding: 6px 15px 10px 15px;
  font-size: 20px;
  border-radius: 4px;
  transition: all 0.3s;

  color: #212b64;
  background: linear-gradient(0deg, #b2cc6f 0%, #f1eb5c 100%);
  border-bottom: 4px solid #6cac85;
  display: block;
  opacity: 1;
}

.action-button:hover {
  top: 2px;
  color: #0b1628;
  border-bottom: 2px solid #6cac85;
  opacity: 0.85;
} */

.apps-button {
  position: relative;
  top: 2px;
  width: 48px;
  display: block;
  height: 48px;
  padding: 8px;
  margin: 0px;
  border-radius: 4px;
  transition: all 0.3s;
}

.apps-button:hover {
  cursor: pointer;
  padding: 4px;
}

.sign-in {
  display: block;
  position: relative;
  top: 0px;
  text-decoration: none;
  padding: 6px 15px 10px 15px;
  font-size: 20px;
  border-radius: 4px;
  transition: all 0.3s;

  color: #dee5b6;
  border: 1px solid #dee5b6;
  border-bottom: 4px solid #dee5b6;
  opacity: 0.85;
}

.sign-in:hover {
  top: 2px;
  border-bottom: 2px solid #dee5b6;
  opacity: 1;
}

.active-under-line {
  background-color: #fff3;
  position: relative;
  opacity: 1 !important;
}

.under-line {
  opacity: 0;
  height: 4px;
  margin-top: 6px;
  width: 100%;
  overflow: hidden;
}

.under-line-in {
  position: absolute;
  left: 0px;
  height: 4px;
  width: 0px;
  background: #839f67;
  /* linear-gradient(90deg, #14819f 0%, #f1eb5c 100%); */
  transition: all 1s;
}

.footer-paralax {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-repeat: no-repeat !important;
  background-size: 100vw !important;
  background-position: bottom right;
}

.section-1-400 {
  min-height: 180px;
  max-height: 180px;
  margin-top: 50px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0px;
  color: #fff;
}

.section-1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 100px);
  width: 100vw;
  margin-top: 100px;
  padding-top: 200px;
}

.header-text {
  padding: 0px 40px 0px 0px;
  position: relative;
  margin: 0px auto;
  margin-left: 140px;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  color: #fff;
  text-shadow: 3px 3px 3px rgb(0 0 0 / 80%);
}

.header-text h2 {
  font-weight: 100;
  font-size: 46px;
  margin: 8px 0px;
}

.header-text span {
  font-weight: 900;
  margin-right: 5px;
}

.header-text p {
  font-size: 34px;
  font-weight: 100;
  margin: 8px 0px;
}

.highlight {
  color: #b552ab
    /*#ff9696*/
  ;
}

.footer {
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  backdrop-filter: saturate(120%) blur(2px);
  background-color: #0003;
  font-size: 14px;
}

.footer-text {
  color: #7b909d;
  text-align: center;
  line-height: 24px;
}

.footer-text a {
  color: #9e9e9e;
}

.footer-text a:hover {
  color: #eee;
}

input[type=text],
input[type=email],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
  font-size: 16px;
  background-color: #fffe;
  color: #0b1628;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

button {
  margin: 0px;
  padding: 10px 20px !important;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  width: 180px;
  height: 48px;
}


label {
  color: #fff;
  font-size: 16px;
  margin: 8px 0px 2px 0px;
  display: block;
}

.error-text {
  color: #ffa2f6;
  margin: 8px 0px 2px 10px;
}

.label-con {
  display: flex;
  align-items: flex-end;
}


.Dropdown-root {
  width: fit-content;
  /* margin-bottom: 16px; */
}

.Dropdown-control {
  background-color: transparent !important;
  border: 0px !important;
  color: #c3c55f !important;
  font-size: 28px !important;
  cursor: pointer !important;
  padding: 8px 52px 8px 10px !important;
  width: fit-content !important;
  font-weight: 500;
}

.Dropdown-arrow {
  border-color: #c3c55f transparent transparent !important;
  top: 26px !important;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #c3c55f !important;
  border-width: 0 5px 5px !important;
}

.Dropdown-menu {
  width: 100% !important;
  border-radius: 2px !important;
}

.Dropdown-option {
  padding: 8px 18px !important;
}

.sideMenu {
  width: 24vw;
  position: absolute;
  top: 130px;
  right: 16px;
  z-index: 10;
  background: #eff0f1;
  border-radius: 3px;
}

.sideMenu::before {
  position: relative;
  content: "";
  display: block;
  top: -25px;
  left: calc(24vw - 76px);
  z-index: 1000;
  height: 0px;
  width: 0px;
  border-style: solid;
  border-color: transparent transparent #fffe transparent;
  border-width: 0 22px 25px 22px;
}

.sideMenu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
}

.sideMenu ul li {
  list-style: none;
  width: 100%;
  height: auto;
  display: flex;
}

.sideMenu ul li a {
  text-decoration: none;
  padding: 15px 15px 15px 25px;
  color: #212b64;
  font-size: 20px;
  border-bottom: 2px solid transparent;
  width: 100%;
  transition: all 0.3s;
}

.sideMenu ul li a.active-link:hover {
  background-color: #0b162855;
  padding: 15px 15px 15px 35px;
}

.sideMenu ul li a.active {
  background-color: #0b162855;
  transition: all 1s;
}

.sideMenu-section {
  display: flex;
  padding: 15px;
  margin-top: -15px;
  border-bottom: 1px solid #0b1628;
}

.side-label {
  font-size: 20px;
  margin-left: 10px;
}

.side-label a {
  text-decoration: none;
  color: #021320;
}

.side-label a:hover {
  text-decoration: none;
  color: #5b596b;
}

.scroll-and-bg-animation-con {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll {
  width: 8px;
  float: right;
  height: 100%;
  /*calc(100% - 32px);*/
  margin: 0px 0px;
  position: relative;
  top: 0px;
  left: calc(50vw - 8px);
  z-index: 0;
}

.menu-closed {
  min-width: 0px !important;
  width: 0px;
}

.menu-closed .menu-main-links,
.menu-closed .nav-links-vertical,
.menu-closed .sideMenu-vertical-section,
.nav-links-vertical.hide {
  scale: 0;
}

.menu {
  min-width: 0px;
  max-width: 0px;
  height: 100vh;
  background-color: #0b1628;
  /* background: linear-gradient(90deg, #1e2632 0%, #232a4e 3%); */
  background: linear-gradient(90deg, #03121e 0%, #021320 10%);
  transition: all 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu-con {
  width: 100%;
}

.hamburger {
  padding-top: 5px;
}


.hamburger .line {
  border-radius: 2px;
  width: 40px;
  height: 5px;
  background-color: #fff;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}


.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

.hamburger-link {
  display: none;
}

.img2 {
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.nav-links2 {
  padding-bottom: 10;
  padding-left: 30px;
  padding-right: 0px;
  display: flex;
  opacity: 1;
  scale: 1;
  transition: all 0.3s;
}

.section1-title {
  font-weight: 900;
  font-size: 48px;
  text-align: center;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}

.section1-subtitle {
  font-weight: 600;
  font-size: 30px;
  color: #ffc1f9;
  text-align: center;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}


.section1-img {
  font-weight: 900;
  font-size: 48px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 75vw;
  display: block;
}

.menu-button-position {
  position: relative;
}

.section-2-text {
  /* color: #ffc1f9; */
  color: #fff;
  padding: 5px 50px;
  font-size: 26px;
  width: 100%;
}

.section-3-text {
  padding: 10px 40px 20px 40px;
  font-size: 26px;
  width: 100%;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.49);
}

.section-3-title-introducing {
  font-size: 36px;
}

.section-3-title-h {
  font-size: 34px;
}

.section-3-title-h2 {
  font-size: 32px;
}


.section-3-title {
  padding: 0px 40px;
  font-size: 30px;
  width: 100%;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.49);
}

.vw-60 {
  width: 60vw;
}

.vw-40 {
  width: 40vw;
}


.vw-70 {
  width: 70vw;
}

.vw-30 {
  width: 30vw;
}

.vw-50 {
  width: 50vw;
}

.hight-vw-50 {
  height: 100%;
}

.vw-50-con {
  width: 100vw;
  height: calc(100% - 230px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 230px;
}

.vw-50-con-video {
  width: 100vw;
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 50px;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85%;
}

.margin-top {
  margin-top: 120px;
  height: calc(100% - 120px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-con {
  padding: 10px 40px 10px 10px;
  width: 70vw;
  height: calc(100vh - 360px);
}

.left-animation {
  width: 30vw;
  height: 30vw;
  padding: 25px;
  margin: 0px auto;
  max-width: 300px;
  max-height: 300px;

  margin-top: 100px;
}

.right-animation {
  width: 70vw;
}


.content-5-margin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 100px);
  margin-top: 100px;
}

.contact-column {
  width: 50vw;
  margin-top: 140px;
  margin-bottom: 100px;
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
}

.message-textarea {
  height: 100px;
}

.scroll-button-up {
  display: block;
  width: 16px;
  height: 24px;
  background: linear-gradient(0deg, #b2cc6f 0%, #f1eb5c 100%);
  border-bottom: 2px solid #53775b;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 10;
  transition: all 0.3s;
  border-radius: 2px;
}

.scroll-button-up:hover {
  border-bottom: 1px solid #53775b;
  opacity: 0.9;
  cursor: pointer;
}

.scroll-button-down {
  display: block;
  width: 16px;
  height: 24px;
  background: linear-gradient(180deg, #b2cc6f 0%, #f1eb5c 100%);
  border-top: 2px solid #53775b;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  transition: all 0.3s;
  border-radius: 2px;
}


.scroll-button-down:hover {
  border-top: 1px solid #53775b;
  opacity: 0.9;
  cursor: pointer;
}

/* Up */
.scroll-button-up:after {
  content: '';
  position: absolute;
  top: 7px;
  right: 4px;
  border-style: solid;
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #555555 transparent;
}

/* Down */
.scroll-button-down:after {
  content: '';
  position: absolute;
  bottom: 7px;
  right: 4px;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #555555 transparent transparent transparent;
}

.stick-header {
  padding-top: 120px !important;
  backdrop-filter: saturate(120%) blur(1.5px);
  background-color: #fff1;
}

@media (max-width: 1200px) {
  header {
    height: 120px;
  }

  .stick-header {
    padding-top: 120px !important;
  }


  .vw-50-con {
    height: calc(100% - 190px);
    margin-top: 190px;
  }

  .nav-link {
    display: none;
  }

  .nav-links {
    display: none;
  }

  .hamburger-link {
    display: flex;
    align-items: center;
  }

  .menu {
    min-width: 300px;
    max-width: calc(100vw - 70px);
  }

  .nav-links2 {
    padding-left: 0px;
    padding-right: 20px;
  }

  .hide-links2 {
    opacity: 0;
    scale: 0;
    width: 0px;
  }

  .section-1-400 {
    min-height: 120px;
    max-height: 120px;
    margin-top: 50px;
  }

  .section1-title {
    font-size: 38px;
  }

  .section1-subtitle {
    font-size: 24px;
  }

  .header-text {
    margin-left: 100px;
  }

  .header-text h2 {
    font-size: 38px;
  }

  .header-text span {
    font-weight: 900;
    margin-right: 5px;
  }

  .header-text p {
    font-size: 28px;
  }

  .section-2-text {
    padding: 1px 50px;
    font-size: 22px;
  }

  .section-3-text {
    padding: 10px 35px 20px 35px;
    font-size: 22px;
  }

  .section-3-title {
    padding: 0px 35px;
    font-size: 24px;
  }

  .section-3-title-h {
    font-size: 30px;
  }

  .section-3-title-h2 {
    font-size: 28px;
  }

  .contact-column {
    width: 100vw;
  }

  /* .contact-column {
    width: calc(100vw + 20px);
    scale: 0.95;
    margin: 0px -10px;
  } */

  .hight-vw-50 {
    height: 50vh;
  }
}

@media (max-width: 900px) {
  header {
    height: 110px;
  }

  .stick-header {
    padding-top: 110px !important;
  }

  .section-3-text {
    padding: 10px 20px 20px 20px;
    font-size: 18px;
  }

  .section-3-title {
    padding: 0px 20px;
    font-size: 20px;
  }

  .section-3-title-h {
    font-size: 26px;
  }

  .section-3-title-h2 {
    font-size: 24px;
  }

  .vw-50-con {
    flex-direction: column;
    height: calc(100% - 180px);
    margin-top: 180px;
  }

  .vw-50-con-video {
    flex-direction: column;
  }

  .vw-50 {
    /* min-width: 50vh; */
    width: 100vw;
    /* height: 50% !important; */
    flex-grow: 1;
  }

  .form-footer {
    flex-direction: column;
  }

  .message-textarea {
    height: 58px;
  }

  input[type=text],
  input[type=email],
  select,
  textarea {
    padding: 8px;
  }

  ::-webkit-scrollbar-button:single-button {
    height: 0px;
  }

  .scroll-button-up,
  .scroll-button-down {
    display: none;
  }

  .scroll {
    margin: 0px;
    height: 100%;
  }

  .feature {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: fit-content;
    flex-grow: 2;
  }
}

@media (max-width: 768px) {
  header {
    height: 100px;
  }

  .stick-header {
    padding-top: 100px !important;
  }

  .logo.hide img {
    max-width: 0px;
  }

  .nav-links2 {
    display: none;
  }

  .section-2-text {
    padding: 1px 30px;
    font-size: 20px;
  }

  .vw-60 {
    width: 100vw;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
  }

  .vw-40 {
    width: 0vw;
    display: none;
  }

  .vw-70 {
    width: 100vw;
  }

  .vw-30 {
    width: 0vw;
    display: none;
  }

  .video-con {
    width: 100vw;
    padding: 10px 20px 10px 10px;
  }

  .left-animation {
    width: 50vw;
    height: 50vw;
    padding: 25px;
    margin: 0px auto;
    max-width: 300px;
    max-height: 300px;
    margin-right: 40px;
    margin-top: 50vh;
    opacity: 0.3;
    margin-top: 55vh;
  }

  .right-animation {
    width: 0vw;
  }

  .hide-768 {
    display: none;
  }

  /* .contact-column {
    width: calc(100vw + 40px);
    scale: 0.9;
    margin: 0px -20px;
    margin-top: 70px;
  } */

  .footer-text {
    width: 100%;
  }

  .form-footer {
    flex-direction: row;
  }

  .content-5-margin {
    margin-top: 500px;
    display: block;
    height: 100vh;
  }


  .section {
    border-bottom: 1px solid #26313e;
    /* display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 2em; */
  }

  .vw-50-con {
    flex-direction: column;
    height: calc(100% - 165px);
    margin-top: 165px;
  }

}


@media (max-width: 768px) and (min-width:500px) {
  .logo.hide .img2 {
    display: block;
    position: absolute;
    top: -14px;
    max-width: 60px;
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .section-1-400 {
    min-height: 100px;
    max-height: 100px;
    margin-top: 50px;
  }

  .section1-title {
    font-size: 28px;
  }

  .section1-subtitle {
    font-size: 20px;
  }

  .header-text {
    margin-left: 12px;
  }

  .header-text h2 {
    font-size: 28px;
  }

  .header-text span {
    font-weight: 900;
    margin-right: 5px;
  }

  .header-text p {
    font-size: 18px;
  }

  .section1-img {
    width: 100vw;
  }

  .section-2-text {
    padding: 1px 10px 1px 0px;
    font-size: 18px;
  }

  .section-3-text {
    padding: 10px 20px 20px 20px;
    font-size: 18px;
  }

  .section-3-title {
    padding: 0px 20px;
    font-size: 20px;
  }

  .section-3-title-introducing {
    font-size: 30px;
  }

  .section-3-title-h {
    font-size: 22px;
  }

  .section-3-title-h2 {
    font-size: 20px;
  }

  .footer {
    padding: 10px;
  }

  .footer-text {
    font-size: 10px;
  }

  .form-footer {
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  header .logo img {
    max-width: 200px;
    transition: all 0.3s;
  }

  .menu-con {
    width: 260px;
  }

  .small-425 {
    font-size: 16px;
    padding: 0px 20px !important;
  }

  .section-3-title-introducing {
    font-size: 24px;
  }
}

@media (max-width: 350px) {


  header .logo .img2 {
    display: block;
    position: absolute;
    top: -14px;
    max-width: 60px;
    opacity: 1;
  }

  header .logo.hide .img2 {
    display: none;
    opacity: 0;
  }

  header .logo img {
    max-width: 0px;
  }

  .menu-button-position {
    position: absolute;
    left: 5px;
  }

  header {
    padding: 20px 25px;
  }

  .footer-text {
    font-size: 9px;
  }
}



.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: fixed;
  top: calc(50% - 100px);
  right: calc(50% - 100px);
  z-index: 999999;
  color: #fff;
  width: 200px;
  height: 200px;
  /* background: radial-gradient(circle, rgb(11, 102, 131) 0%, rgba(11, 102, 131, 0) 70%, rgba(11, 102, 131, 0) 90%, rgba(11, 102, 131, 0) 100%); */
}

.loader .progress-bar {
  width: 200px;
  height: 5px;
  border: 1px solid #fff;
  overflow: hidden;
  margin: 10px auto;
}

.progress-bar .progress {
  height: 100%;
  background-color: #dde0c9;
  transition: all 0.3s;
}

.loader .per {
  margin: 0px auto;
}

.loader-background {
  position: absolute;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgb(11, 102, 131) 0%, rgba(11, 102, 131, 0) 70%, rgba(11, 102, 131, 0) 90%, rgba(11, 102, 131, 0) 100%);
}

.logo-animation {
  width: 80px;
  margin: 20px auto;
}

.logo-animation .animated-path {
  fill: none;
  stroke: #dde0c9;
  stroke-width: 50px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 3315;
  /*stroke-dasharray: 3315 3315 2000 1315;*/
  stroke-dashoffset: 0;
  /*animation: draw1 2s linear infinite;*/
}

.logo-animation .solid-path {
  fill: none;
  stroke: #ffffff22;
  stroke-width: 50px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.logo-animation .animate {
  animation: draw-logo 2s linear infinite;
}

@keyframes draw-logo {
  0% {
    stroke-dashoffset: 0;
  }

  20% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 6630;
  }
}

.min-height {
  min-height: 700px;
}

.contact-form {
  padding: 0px 20px 0px 10px;
  width: 100%;
  direction: ltr;
}